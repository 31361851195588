//
// cta.scss
//


.bg-cta{
    position: relative;
    padding: 105px 0;
    // background-color: #d8faea;
    background-image: url('../images/bg-cta.png');
    background-size: cover;
    background-position: bottom;
    background-repeat: no-repeat;
}
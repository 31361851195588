//
// helper.scss
//

/*******color Loop*******/
@each $color, $value in $theme-colors {
  .text-#{$color} {
    color: $value !important;
  }
  .bg-#{$color} {
    background-color: $value !important;
  }
}

.section {
  padding-top: 80px;
  padding-bottom: 80px;
  position: relative;
}

.bg-overlay {
  background-color: rgba(#173439, 0.95);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

// font-size

.fs-12 {
  font-size: 12px;
}

.fs-14 {
  font-size: 14px;
}

.fs-15 {
  font-size: 15px;
}

.fs-16 {
  font-size: 16px;
}

.fs-17 {
  font-size: 17px;
}

.fs-18 {
  font-size: 18px;
}

.fs-20 {
  font-size: 20px;
}

/*******font-weight*******/
.fw-medium {
  font-weight: $font-weight-medium;
}

.fw-semibold {
  font-weight: $font-weight-semibold;
}

// box-shadow

.box-shadow {
  box-shadow: $box-shadow;
}

.box-shadow-lg {
  box-shadow: 0px 10px 28px rgba($black, 0.1);
}

// heading

.title-desc {
  max-width: 650px;
  margin: 0 auto;
}
.title-border {
  width: 80px;
  height: 1px;
  background: $primary;
  background: linear-gradient(to right, transparent, $primary, transparent);
  margin: 0 auto;
}

#back-to-top {
  width: 30px;
  height: 30px;
  position: fixed;
  bottom: 20px;
  right: 30px;
  background: $dark;
  border-color: transparent;
  border-radius: 3px;
  color: $white;
  transition: all 0.5s ease;
  display: none;
  &:hover {
    background-color: $primary;
  }
}

.primary-link {
  color: $dark;
  transition: all 0.5s ease;
  &:hover {
    color: $primary;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px !important;
  }
}
